import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import {
  faEllipsisV,
  faUser,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';
import {
  Box,
  Body,
  FAIcon,
  Flex,
  Text,
  H5,
  DataTable,
  SecondaryOutlinedButton,
  ActionMenu,
  ActionMenuItem,
  ActionMenuText,
  Tooltip,
  useApi,
} from '@fivehealth/botero';

import {
  checkMaxSelectionThreshold,
  showMaxSelectionThresholdMessage,
  tableConfigUtils,
} from 'AppUtils';
import { ActionMenuType } from 'constants';

import Colors from 'constants/colors';

import TableLoader from 'components/Table/TableLoader';
import ActionDropdown from 'components/ActionDropdown/ActionDropdown';
import { PATIENT_LIST_SELECTION_OPTION } from 'Config';
import { clone, get, isEmpty } from 'lodash';
import { PrimaryButton } from '@fivehealth/botero/build/components/Button/PrimaryButton';
import { useAppData } from 'context/AppDataContext';
import Table from 'components/Table/Table';
import MobileTable from 'components/Table/MobileTable';
import SendMessageButton from 'components/SendMessageButton/SendMessageButton';
import Trash from 'components/icons/Trash';
import Sms from 'components/icons/Sms';
import WhatsApp from 'components/icons/WhatsApp';
import WebMessenger from 'components/icons/WebMessenger';
import Viber from 'components/icons/Viber';
import LineMessenger from 'components/icons/LineMessenger';
import Email from 'components/icons/Email';
import {
  ACTIVE_PATIENTS_CONFIG,
  DISCHARGE_PATIENTS_CONFIG,
} from './defaultConfigs';
import BotCryingAvatar from '../../assets/crying-avatar.svg';
import BotSearchAvatar from '../../assets/bot-search-avatar.svg';
import SendMessageIcon from '../../assets/paper-plane.png';

const ActionsCell = ({
  t,
  cell,
  deactivated,
  getActionMenu,
  isMobile,
  onShowPatientForm,
  onSendMessage,
}) => (
  <Flex
    alignItems="center"
    height={[56, 56, null]}
    pl={[2, 2, null]}
    pr={[2, 2, null]}
  >
    <Box display={['none', 'none', 'block']}>
      <Flex alignItems="center">
        <Flex
          testid="patient_view_patient_profile"
          px={2}
          height={30}
          alignItems="center"
          onClick={() => onShowPatientForm({ patient: cell.row.original })}
          cursor="pointer"
        >
          <FAIcon
            tooltipText={t('View profile')}
            icon={faUser}
            style={{ fontSize: 20 }}
          />
        </Flex>
        {!deactivated && (
          <Box pl={2} pt="5px">
            <SendMessageButton
              onClick={() => onSendMessage(cell.row.original)}
            />
          </Box>
        )}
      </Flex>
    </Box>

    <Flex flex="1" alignItems="center" justifyContent="space-between">
      {isMobile && (
        <>
          <Flex
            px={1}
            onClick={() => onShowPatientForm({ patient: cell.row.original })}
            cursor="pointer"
          >
            <FAIcon icon={faUser} style={{ fontSize: 20, marginRight: 10 }} />
            <H5 mt="2px" color="darkestShade">
              About
            </H5>
          </Flex>

          {!deactivated && (
            <Flex
              px={2}
              onClick={() => onSendMessage(cell.row.original)}
              cursor="pointer"
            >
              <Box
                mr="8px"
                as="img"
                src={SendMessageIcon}
                height={30}
                width={30}
                alt="send message"
              />
              <H5 mt="2px" color="darkestShade">
                Message
              </H5>
            </Flex>
          )}
        </>
      )}

      <ActionDropdown
        testid="patient_list_action_menu"
        label={
          isMobile && (
            <Flex
              alignItems="center"
              justifyContent="space-between"
              pl={isMobile ? '16px' : 0}
              pr={isMobile ? '16px' : 0}
            >
              <Flex mr="10px">
                <FAIcon icon={faEllipsisV} hover={{ opacity: 0.6 }} />
              </Flex>

              <Flex>
                <Text fontWeight="600" color="darkestShade">
                  {t('More')}
                </Text>
              </Flex>
            </Flex>
          )
        }
        dropdownOptions={getActionMenu(
          deactivated ? ActionMenuType.Discharge : ActionMenuType.Active,
          { patient: cell.row.original }
        )}
      />
    </Flex>
  </Flex>
);

const PatientFormTable = forwardRef(
  (
    {
      getActionMenu,
      data,
      hasNextPage,
      initialSortBy,
      isFetched,
      isFetchingNextPage,
      onFetchData,
      onFetchNextPage,
      onShowPatientForm,
      showLoading,
      deactivated = false,
      clinic,
      onSendMessage,
      onAddFormBulk,
      onCreateGroup,
      onBulkDischarge,
      onBulkDeactivate,
      // onBulkActivate,
      enableRowSelect,
      setEnableRowSelect,
      autoResetSelectedRows,
      setAutoResetSelectedRows,
      selectionOptionChosen,
      removedSelection,
      setRemovedSelection,
      patientGroupSelection,
      onSavePatientsGroup,
      onRowSelectionCallback,
      onCancelCallback,
      setShowLoadMoreButton,
      showLoadMoreButton,
      searchText,
      onResetFilters,
      onSearch,
      filtersRef,
    },
    ref
  ) => {
    const {
      queries: { useClinicProgramsCount },
    } = useApi({
      queries: ['useClinicProgramsCount'],
    });

    const { data: clinicProgramsCount } = useClinicProgramsCount();

    const { t } = useTranslation();
    const { sidebarOpened } = useAppData();
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });

    const [selectedRows, setSelectedRows] = useState([]);

    const [rowsSelectedCount, setRowsSelectedCount] = useState(0);

    const dropdownRef = useRef();
    const toggleAllPageRowsSelectedRef = useRef();
    const toggleAllRowsSelectedRef = useRef();

    const getTableConfig = useCallback(() => {
      // console.log(
      //   'clinic?.activePatientListTableSettings',
      //   clinic?.activePatientListTableSettings
      // );

      if (deactivated) {
        if (isEmpty(clinic?.dischargedPatientListTableSettings)) {
          return DISCHARGE_PATIENTS_CONFIG;
        }
        return clinic?.dischargedPatientListTableSettings;
      }
      if (isEmpty(clinic?.activePatientListTableSettings)) {
        return ACTIVE_PATIENTS_CONFIG;
      }
      return clinic?.activePatientListTableSettings;
    }, [
      deactivated,
      clinic?.activePatientListTableSettings,
      clinic?.dischargedPatientListTableSettings,
    ]);

    const tableConfigSettings = getTableConfig();

    const getChannelIcon = (communicationMethod, color) => {
      switch (communicationMethod) {
        case 'WHATSAPP':
          return <WhatsApp color={color} width={23} height={23} />;
        case 'VIBER':
          return <Viber color={color} width={23} height={23} />;
        case 'LINE':
          return <LineMessenger color={color} width={23} height={23} />;
        case 'MESSENGER':
          return <WebMessenger color={color} width={23} height={23} />;
        case 'EMAIL':
          return <Email color={color} width={15} height={15} />;
        default:
          return <Sms color={color} width={24} height={25} />;
      }
    };

    const getChannelIconTextAndColor = (cell) => {
      const {
        isDeviceCreated,
        isDeviceSubscribed,
        isFollower,
        communicationMethod,
      } = cell.row.original;

      if (!isDeviceCreated) {
        return {
          text: t('No device'),
          color: Colors.danger,
          backgroundColor: Colors.dangerWithOpacity,
          icon: <Trash color={Colors.red} width={23} height={23} />,
          tooltipContent: {
            device: t('Not created'),
            channel: communicationMethod,
            notification: t('Not subscribed'),
          },
        };
      }

      if (isDeviceSubscribed && isFollower) {
        return {
          text: t('Subscribed'),
          color: Colors.success,
          backgroundColor: Colors.successWithOpacity,
          icon: getChannelIcon(communicationMethod, Colors.success),
          tooltipContent: {
            device: isDeviceCreated ? t('Created') : t('Not created'),
            channel: communicationMethod,
            notification: t('Subscribed'),
          },
        };
      }

      if (isDeviceSubscribed && !isFollower) {
        return {
          text: t('Not follower'),
          color: Colors.red,
          backgroundColor: Colors.dangerWithOpacity,
          icon: getChannelIcon(communicationMethod, Colors.red),
          tooltipContent: {
            device: isDeviceCreated ? t('Created') : t('Not created'),
            channel: communicationMethod,
            notification: t('Unsubscribed'),
          },
        };
      }

      if (!isDeviceSubscribed && isFollower) {
        return {
          text: t('Unsubscribed'),
          color: Colors.danger,
          backgroundColor: Colors.dangerWithOpacity,
          icon: getChannelIcon(communicationMethod, Colors.red),
          tooltipContent: {
            device: isDeviceCreated ? t('Created') : t('Not created'),
            channel: communicationMethod,
            notification: t('Unsubscribed'),
          },
        };
      }
      if (!isDeviceSubscribed && !isFollower) {
        return {
          text: t('Unsubscribed'),
          color: Colors.danger,
          backgroundColor: Colors.dangerWithOpacity,
          icon: getChannelIcon(communicationMethod, Colors.red),
          tooltipContent: {
            device: isDeviceCreated ? t('Created') : t('Not created'),
            channel: communicationMethod,
            notification: t('Unsubscribed'),
          },
        };
      }

      return {
        text: t('Unspecified'),
        color: Colors.danger,
        backgroundColor: Colors.dangerWithOpacity,
        icon: <Trash color={Colors.danger} width={23} height={23} />,
        tooltipContent: {
          device: isDeviceCreated ? t('Created') : t('Not created'),
          channel: communicationMethod,
          notification: t('Unsubscribed'),
        },
      };
    };

    const renderChannelStatus = (cell) => {
      const { text, color, backgroundColor, icon, tooltipContent } =
        getChannelIconTextAndColor(cell);
      return (
        <Tooltip
          tooltip={
            <Box px="5px" pt="4px">
              <Text color={Colors.emptyShade} mb={1}>
                {t('Device')}: {tooltipContent.device}
              </Text>
              <Text color={Colors.emptyShade} mb={1}>
                {t('Channel')}: {tooltipContent.channel}
              </Text>
              <Text color={Colors.emptyShade} mb={1}>
                {t('Notfications')}: {tooltipContent.notification}
              </Text>
            </Box>
          }
        >
          <Flex
            borderRadius={6}
            backgroundColor={backgroundColor}
            justifyItems="center"
            justifyContent="center"
            alignItems="center"
            width="fit-content"
            padding="4px 8px"
          >
            {icon}

            <Text ml={1} color={color} fontWeight="500">
              {text}
            </Text>
          </Flex>
        </Tooltip>
      );
    };

    const columns = useMemo(
      () => {
        const cols = [
          ...tableConfigUtils.mapCellRenderToConfig(
            tableConfigSettings,
            clinic,
            t,
            'YYYY-MM-DD'
          ),
        ];

        if (clinicProgramsCount > 0 && !deactivated) {
          cols.splice(4, 0, {
            id: 'activeProgram',
            Header: t('Active Programs'),
            disableSortBy: true,
            accessor: (row) => {
              const programs = get(row, 'patientPrograms.edges', [])
                .map((program) => program.node?.clinicProgram?.name)
                .filter(Boolean)
                ?.join(', ');
              return programs;
            },
          });
          if (cols?.[5] && cols?.[6]) {
            cols[5].Header = t('Active Forms');
            cols[6].Header = t('Removed Forms');
          }
        } else if (clinicProgramsCount > 0 && deactivated) {
          if (cols?.[3]) {
            cols[3].Header = t('Removed Forms');
          }
        }

        return cols;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [clinic, t, tableConfigSettings, enableRowSelect, clinicProgramsCount]
    );

    // Insert channel column at index 2
    if (columns.filter((c) => c.id === 'channel').length === 0) {
      columns.splice(2, 0, {
        id: 'channel',
        Header: t('Channel'),
        disabledSortBy: true,
        Cell: renderChannelStatus,
      });
    }

    // Prevent adding action menu twice.
    if (
      !patientGroupSelection &&
      columns.filter((c) => c.id === 'actions').length === 0 &&
      !enableRowSelect
    ) {
      columns.push({
        id: 'actions',
        Header: '',
        Cell: (props) => ActionsCell({ ...props, onSendMessage }),
        disableSortBy: true,
        width: '150px',
      });
    }

    const cancelSelectedRows = useCallback(
      (deselect = false, toggleAllRowsSelected, toggleAllPageRowsSelected) =>
        new Promise((resolve) => {
          setAutoResetSelectedRows(true);
          toggleAllRowsSelected(false);
          toggleAllPageRowsSelected(false);
          setRowsSelectedCount(0);
          setSelectedRows([]);
          setRemovedSelection([]);
          setShowLoadMoreButton(true);
          if (!deselect) {
            setEnableRowSelect(false);
          }
          resolve();
        }),
      [
        setAutoResetSelectedRows,
        setEnableRowSelect,
        setRemovedSelection,
        setShowLoadMoreButton,
      ]
    );

    useImperativeHandle(ref, () => ({
      resetAllPagesSelectedRows() {
        if (toggleAllRowsSelectedRef.current) {
          setRowsSelectedCount(0);
          setRemovedSelection([]);
          setSelectedRows([]);
          toggleAllRowsSelectedRef.current(false);
        }
      },
      selectAllPagesRows(count) {
        setEnableRowSelect(true);
        setAutoResetSelectedRows(false);
        setRowsSelectedCount(count);
        setSelectedRows(clone(data));
        toggleAllPageRowsSelectedRef.current(true);
      },
      selectAllVisibleRows(count) {
        setEnableRowSelect(true);
        setAutoResetSelectedRows(false);
        setRowsSelectedCount(count);
        setSelectedRows(clone(data));
        toggleAllRowsSelectedRef.current(true);
      },
      getSelectedPatients() {
        if (selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS) {
          return [];
        }
        return selectedRows;
      },
    }));

    // Commented out for all because we dont allow custom on select all
    /* const unSelectRemovedRow = useCallback(() => {
      removedSelection.forEach((row) => {
        row.toggleRowSelected();
      });
    }, [removedSelection]); */

    const getUniqueElements = (arr1, arr2) => {
      // Use the spread operator to concatenate the arrays
      const combinedArr = [...arr1, ...arr2];

      // Use the filter() method to return only elements that are not in arr1
      const uniqueArr = combinedArr.filter((item) => !arr1.includes(item));

      return uniqueArr;
    };

    const runCleaner = (selectedFlatRows) => {
      const uniqs = getUniqueElements(selectedRows, selectedFlatRows);
      if (uniqs.length !== rowsSelectedCount) {
        uniqs.forEach((row) => {
          row.toggleRowSelected(false);
        });
      }
    };

    /**
     * Keep track of unselected patients when select option is "Select all rows"
     * User can select all patients, then search a few patients to unselect, this logic keeps track of that.
     */
    useEffect(() => {
      if (
        selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS &&
        selectedRows.length > 0
      ) {
        toggleAllPageRowsSelectedRef.current(true);
        /* This is not used anymore because we don't allow custom selection after Select All is pressed */
        // unSelectRemovedRow();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, selectionOptionChosen]);

    const props = useMemo(
      () => ({
        columns,
        data: clone(data),
        deactivated,
        getActionMenu,
        isFetched,
        isFetchingNextPage,
        isMobile,
        hasNextPage,
        initialSortBy: initialSortBy ?? {
          id: 'NAME',
          desc: false,
        },
        t,
        onFetchNextPage,
        onFetchData,
        onShowPatientForm,
        showDischargedLabel: !!deactivated,
        autoResetSelectedRows,
      }),
      [
        autoResetSelectedRows,
        columns,
        data,
        deactivated,
        getActionMenu,
        isFetched,
        isFetchingNextPage,
        isMobile,
        hasNextPage,
        t,
        onFetchNextPage,
        onFetchData,
        onShowPatientForm,
        initialSortBy,
      ]
    );

    const handleTableRowClick = (row) => {
      if (checkMaxSelectionThreshold(rowsSelectedCount - 1)) {
        showMaxSelectionThresholdMessage();
        return;
      }
      if (row?.original?.disableCheckbox) return;
      if (!enableRowSelect) return;

      if (autoResetSelectedRows) {
        setAutoResetSelectedRows(false);
      }

      const index = removedSelection
        ? removedSelection.findIndex(
            (selectedRow) => selectedRow.original.uid === row.original.uid
          )
        : -1;

      const selectedRowIndex = selectedRows
        ? selectedRows.findIndex(
            (selectedRow) =>
              (selectedRow.id ?? selectedRow.original.uid) === row.original.uid
          )
        : -1;

      if (row.isSelected) {
        setRemovedSelection([...removedSelection, row]);
        setRowsSelectedCount(rowsSelectedCount - 1);

        selectedRows.splice(selectedRowIndex, 1);
        setSelectedRows(clone(selectedRows));
      } else {
        removedSelection.splice(index, 1);
        setRemovedSelection([...removedSelection]);
        setRowsSelectedCount(rowsSelectedCount + 1);

        setSelectedRows(clone([...selectedRows, row]));
      }

      row.toggleRowSelected();
    };

    // const showDeselectAllButton = useMemo(
    //   () =>
    //     selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.VISIBLE_ROWS ||
    //     selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.CUSTOM,
    //   [selectionOptionChosen]
    // );

    const renderRowSelectInfo = useCallback(
      ({
        selectedFlatRows,
        toggleAllRowsSelected,
        toggleAllPageRowsSelected,
      }) => {
        toggleAllRowsSelectedRef.current = toggleAllRowsSelected;

        toggleAllPageRowsSelectedRef.current = toggleAllPageRowsSelected;

        if (onRowSelectionCallback) {
          onRowSelectionCallback(selectedFlatRows);
        }

        return (
          <Flex
            mr={3}
            style={{
              borderRadius: 5,
              position: 'fixed',
              bottom: '24px',
              width: sidebarOpened ? '80%' : '91%',
            }}
            p={2}
            justifyContent="space-between"
            alignItems="center"
            bg="lightBlue"
          >
            <Flex alignItems="center">
              <Text color="primary" fontWeight="bold" mr={1}>
                {rowsSelectedCount} {t('patients')} {t('selected')}
              </Text>

              <SecondaryOutlinedButton
                onClick={() =>
                  cancelSelectedRows(
                    true,
                    toggleAllRowsSelected,
                    toggleAllPageRowsSelected
                  )
                }
                mr={2}
                bg="white"
              >
                {t('Deselect all')}
              </SecondaryOutlinedButton>
            </Flex>

            <Flex alignItems="center">
              <SecondaryOutlinedButton
                onClick={() => {
                  cancelSelectedRows(
                    false,
                    toggleAllRowsSelected,
                    toggleAllPageRowsSelected
                  );

                  onSearch('');
                  setTimeout(() => {
                    onResetFilters();
                    filtersRef?.current?.clearFilters();
                  }, 500);
                  onCancelCallback();
                }}
                mr={2}
                bg="white"
              >
                {t('Cancel')}
              </SecondaryOutlinedButton>

              {patientGroupSelection && (
                <PrimaryButton
                  onClick={onSavePatientsGroup}
                  disabled={!(selectedRows.length > 0)}
                >
                  {t('Save group')}
                </PrimaryButton>
              )}

              {!patientGroupSelection && (
                <Box>
                  <ActionMenu
                    minWidth={150}
                    dropdownRef={(r) => {
                      dropdownRef.current = r;
                    }}
                    label={
                      <SecondaryOutlinedButton
                        hover="green"
                        borderColor="transparent"
                        bgHovered={
                          selectedRows.length > 0 ? 'primary' : Colors.lightBlue
                        }
                        bg={
                          selectedRows.length > 0 ? 'primary' : Colors.lightBlue
                        }
                        borderRadius={8}
                        endIcon={
                          <FAIcon
                            icon={faChevronDown}
                            fontSize="14px"
                            style={{ color: 'white' }}
                          />
                        }
                      >
                        <Body
                          width="100%"
                          color="white"
                          fontFamily="Inter"
                          small
                          medium
                        >
                          {t('Bulk actions')}
                        </Body>
                      </SecondaryOutlinedButton>
                    }
                  >
                    <>
                      {rowsSelectedCount === 0 && (
                        <ActionMenuItem>
                          <ActionMenuText>
                            {t('No rows selected')}
                          </ActionMenuText>
                        </ActionMenuItem>
                      )}
                      {!deactivated && rowsSelectedCount > 0 && (
                        <>
                          <ActionMenuItem
                            onClick={() => onAddFormBulk(selectedRows)}
                          >
                            <ActionMenuText>
                              {t('Enroll Into Program')}
                            </ActionMenuText>
                          </ActionMenuItem>

                          <ActionMenuItem
                            onClick={() => onCreateGroup(selectedRows)}
                          >
                            <ActionMenuText>{t('Create Group')}</ActionMenuText>
                          </ActionMenuItem>

                          <ActionMenuItem
                            onClick={() => {
                              onSendMessage(undefined, selectedRows);
                            }}
                          >
                            <ActionMenuText>{t('Send Message')}</ActionMenuText>
                          </ActionMenuItem>

                          <ActionMenuItem
                            onClick={() => onBulkDischarge(selectedRows)}
                          >
                            <ActionMenuText color={Colors.red}>
                              {t('Remove Programs')}
                            </ActionMenuText>
                          </ActionMenuItem>

                          <ActionMenuItem
                            divider
                            onClick={() => onBulkDeactivate(selectedRows)}
                          >
                            <ActionMenuText color={Colors.red}>
                              {t('Deactivate Patients')}
                            </ActionMenuText>
                          </ActionMenuItem>
                        </>
                      )}

                      {deactivated && (
                        <ActionMenuItem
                          onClick={() => onAddFormBulk(selectedRows)}
                        >
                          <ActionMenuText>
                            {t('Bulk activate and enroll')}
                          </ActionMenuText>
                        </ActionMenuItem>
                      )}

                      {/* {deactivated && (
                      <ActionMenuItem
                        divider
                        onClick={() => onBulkActivate(selectedRows)}
                      >
                        <ActionMenuText>
                          {t('Activate Patients')}
                        </ActionMenuText>
                      </ActionMenuItem>
                    )} */}
                    </>
                  </ActionMenu>
                </Box>
              )}
            </Flex>
          </Flex>
        );
      },
      [
        cancelSelectedRows,
        deactivated,
        onAddFormBulk,
        onBulkDeactivate,
        onBulkDischarge,
        onCancelCallback,
        onRowSelectionCallback,
        onSendMessage,
        patientGroupSelection,
        rowsSelectedCount,
        selectedRows,
        onSavePatientsGroup,
        sidebarOpened,
        t,
        onCreateGroup,
        onResetFilters,
        onSearch,
        filtersRef,
      ]
    );

    const disableRow = useMemo(
      () => selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS,
      [selectionOptionChosen]
    );

    if (showLoading) {
      return <TableLoader />;
    }

    if (!clinic) {
      return null;
    }

    const removeDuplicatesById = (arr) => {
      const uniqueArray = [];
      const idSet = new Set();

      arr.forEach((obj) => {
        if (!idSet.has(obj.id)) {
          uniqueArray.push(obj);
          idSet.add(obj.id);
        }
      });

      return uniqueArray;
    };

    return (
      <Box overflow="scroll" className="scrollbar-invisible">
        {!isMobile && (
          <DataTable
            disableCheckboxes={disableRow}
            columns={columns}
            mb={4}
            show="false"
            hideHeaderSelectionCheckBox
            hasNextPage={hasNextPage}
            {...props}
            onTableDataChanged={({ selectedRowIds, pages }) => {
              const idsConcat = Object.keys(selectedRowIds).join(',');

              const allSelectedRows = pages.filter((page) =>
                idsConcat.includes(page.id)
              );

              const removedDuplicates = removeDuplicatesById([
                ...allSelectedRows,
                ...selectedRows,
              ]);

              if (
                selectionOptionChosen === PATIENT_LIST_SELECTION_OPTION.ALL_ROWS
              ) {
                setSelectedRows(removedDuplicates);
              }

              if (
                selectionOptionChosen !==
                  PATIENT_LIST_SELECTION_OPTION.ALL_ROWS &&
                removedDuplicates.length !== selectedRows.length
              ) {
                runCleaner(removedDuplicates);
              }
            }}
            showLoadMore={showLoadMoreButton}
            data={data}
            showRowSelectedBar={enableRowSelect}
            renderRowSelectInfo={renderRowSelectInfo}
            onRowClick={handleTableRowClick}
            enableRowSelect={enableRowSelect}
            autoResetSelectedRows={autoResetSelectedRows}
            noResultsProps={{
              title: searchText ? t('No results found') : t('No patients'),
              description: searchText
                ? t(
                    'Try adjusting your search or filter to find what you’re looking for.'
                  )
                : t('You can add a new patient by clicking Add Patient above.'),
              avatarProps: {
                height: '200px',
                width: '200px',
                src: searchText ? BotSearchAvatar : BotCryingAvatar,
              },
            }}
          />
        )}
        {isMobile && <Table renderTable={MobileTable} {...props} />}
      </Box>
    );
  }
);

PatientFormTable.defaulProps = {
  onShowSubmission: () => {},
  onShowPatientForm: () => {},
};

export default PatientFormTable;
