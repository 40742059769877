import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinic {
    cleoClinic {
      uid
      domain
      name
      logo
      settings
      patientChartsSettings
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'clinicAsPatient',
  keepPreviousData: true,
  select: ({ cleoClinic }) => cleoClinic,
});
