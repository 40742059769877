import { gql } from 'graphql-request';
import { createQuery } from '@fivehealth/botero';

const GRAPHQL_DOCUMENT = gql`
  query cleoClinic {
    cleoClinic {
      uid
      domain
      name
      logo
      clinicianCount
      settings
      caregiverRole {
        uid
      }
      enrollmentForm {
        url
      }
      communicationMethods {
        gql
        label
        value
      }
      allPatientFormsTableSettings
      unresolvedAlertsPatientFormsTableSettings
      nonCompliantPatientFormsTableSettings
      patientSubmissionsTableSettings
      activePatientFormsTableSettings
      dischargedPatientFormsTableSettings
      patientChartsSettings
      activePatientListTableSettings
      dischargedPatientListTableSettings
      billingSettings
      isClinicBilling
    }
  }
`;

export default createQuery({
  gqlDocument: GRAPHQL_DOCUMENT,
  queryType: 'query',
  baseQueryKey: 'clinic',
  keepPreviousData: true,
  select: ({ cleoClinic }) => cleoClinic,
});
