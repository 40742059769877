import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActionMenu,
  ActionMenuItem,
  Box,
  Flex,
  H5,
  H4,
  FAIcon,
  useApi,
  useAuth,
  Drawer,
} from '@fivehealth/botero';
import {
  faChevronDown,
  faBars,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { useLocation, Link } from 'react-router-dom';
import { useAppData } from 'context/AppDataContext';
import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useTranslation, withTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useQueryClient } from 'react-query';
import { useModal } from 'context/ModalContext';
import Sidebar from 'components/Sidebar/Sidebar';
import ChangeProfilePicture from './ChangeProfilePicture.modal';
import ChangeClinicianLanguage from './ChangeClinicianLanguage.modal';
import ChangeWorkspace from './ChangeWorkspace.modal';
import Config from '../../Config';
import { domainConfigsUtils, getBaseUrl } from '../../AppUtils';

const blobToBase64 = (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      resolve(dataUrl);
    };
    reader.readAsDataURL(blob);
  });

const getBase64Image = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const base64 = await blobToBase64(blob);
  return base64;
};

const getProfilePic = (user) => {
  if (user) {
    const profilePhoto = _.get(user, 'profilePhoto');
    if (profilePhoto) {
      return profilePhoto;
    }

    const avatar = _.get(user, 'metadata.avatar');
    if (avatar) {
      return `/${avatar}.svg`;
    }
  }
  return '/avatar_blue_male.svg';
};

const UserInfo = ({ user = {} }) => (
  <Flex alignItems="center">
    {user ? <H5>{user.name}</H5> : ''}
    <Box
      ml={2}
      mr={2}
      as="img"
      src={getProfilePic(user)}
      borderRadius="50%"
      width={40}
    />
    <FAIcon icon={faChevronDown} fontSize="18px" />
  </Flex>
);

const ProfileActionMenu = withTranslation()(
  ({
    user,
    openModal,
    closeModal,
    isUpdating,
    onSubmit,
    logout,
    label,
    t,
    isPatientFacing,
    selectedlanguage,
    selectedClinic,
    clinics,
    isCaregiverFacing,
  }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 720px)' });
    const getMobileWidthHeight = {
      style: {
        width: 450,
        padding: 24,
      },
    };

    return (
      <ActionMenu label={label} ml={isMobile ? '-16px' : '0px'}>
        {!isPatientFacing && (
          <ActionMenuItem
            onClick={() =>
              openModal(
                <ChangeProfilePicture
                  closeModal={closeModal}
                  isUpdating={isUpdating}
                  onSubmit={onSubmit}
                  user={user}
                />,
                {
                  overflow: 'visible',
                  minWidth: isMobile ? 350 : 350,
                  maxWidth: isMobile ? 350 : 350,
                  maxHeight: isMobile ? 350 : 350,
                  minHeight: isMobile ? 350 : 350,
                }
              )
            }
          >
            {t('Change profile picture')}
          </ActionMenuItem>
        )}
        {!isPatientFacing && !isCaregiverFacing && (
          <>
            <ActionMenuItem
              onClick={() =>
                openModal(
                  <ChangeClinicianLanguage
                    closeModal={closeModal}
                    isUpdating={isUpdating}
                    onSubmit={onSubmit}
                    isMobile={isMobile}
                    lang={selectedlanguage}
                  />,
                  getMobileWidthHeight
                )
              }
            >
              {t('Change language')}
            </ActionMenuItem>
            {clinics?.length > 0 && (
              <ActionMenuItem
                onClick={() =>
                  openModal(
                    <ChangeWorkspace
                      closeModal={closeModal}
                      onSubmit={onSubmit}
                      isMobile={isMobile}
                      selectedClinic={selectedClinic}
                      clinics={clinics}
                    />,
                    getMobileWidthHeight
                  )
                }
              >
                {t('Change workspace')}
              </ActionMenuItem>
            )}
          </>
        )}
        <ActionMenuItem onClick={logout}>{t('Logout')}</ActionMenuItem>
      </ActionMenu>
    );
  }
);

const Header = ({ currentUser, refetchUser }) => {
  const domainConfigs = domainConfigsUtils.getDomainConfigs();
  const { openModal, closeModal } = useModal();
  const { logout: logoutUser, authState } = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [cookies, setCookie] = useCookies([Config.cookie.name]);
  const location = useLocation();
  const { i18n } = useTranslation();
  const { user, clinics, clinic, resetAppState } = useAppData();
  const queryClient = useQueryClient();

  const {
    queries: {
      useUpdateClinician,
      useHeimdallAuthorizationFlowSessionExchange,
      useHeimdallLogout,
    },
  } = useApi({
    queries: [
      'useUpdateClinician',
      'useHeimdallAuthorizationFlowSessionExchange',
      'useHeimdallLogout',
    ],
  });

  const { mutateAsync: logoutMutateAsync } = useHeimdallLogout({
    variables: {},
  });

  const logout = useCallback(async () => {
    try {
      logoutUser();
      await logoutMutateAsync({ input: {} });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [logoutMutateAsync, logoutUser]);

  const loadInitialDashboardSettings = useCallback(() => {
    if (!_.isEmpty(currentUser) && !_.isEmpty(clinic)) {
      i18n.changeLanguage(
        currentUser?.metadata?.language || clinic?.settings?.language
      );
      const { domain, name, uid } = clinic;
      const workspace = {
        domain,
        uid,
        name,
      };
      setSelectedWorkspace(workspace);
    }
  }, [setSelectedWorkspace, currentUser, clinic, i18n]);

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  useEffect(() => {
    loadInitialDashboardSettings();
  }, [currentUser, clinic, clinics, loadInitialDashboardSettings]);

  const { mutateAsync: heimdallAuthorizationFlowSessionExchange } =
    useHeimdallAuthorizationFlowSessionExchange({
      variables: {},
    });

  const { mutateAsync: updateClinician, isLoading: isUpdating } =
    useUpdateClinician({
      variables: {},
    });

  const onSubmit = useCallback(
    async (updateType, payload) => {
      try {
        if (updateType === 'Avatar') {
          await updateClinician({
            input: {
              avatar: payload,
              uid: currentUser.uid,
            },
          });
        } else if (updateType === 'Profile') {
          const dataUri = await getBase64Image(`${window.origin}${payload}`);
          await updateClinician({
            input: {
              profilePhoto: dataUri,
              uid: currentUser.uid,
            },
          });
        } else if (updateType === 'Workspace') {
          setSelectedWorkspace(payload);
          const input = {
            providerApplicationUid:
              Config.CLEO_SESSION_EXCHANGE_PROVIDER_UID[
                domainConfigs?.hippocrates?.region
              ],
            providerInput: {
              session: cookies[Config.cookie.name],
            },
            applicationInput: {
              domain: payload.domain,
            },
          };
          const {
            heimdallAuthorizationFlow: {
              session: { uid },
            },
          } = await heimdallAuthorizationFlowSessionExchange({
            heimdallAuthorizationFlowInput: input,
          });
          setSelectedWorkspace((data) => ({ ...data, uid }));
          resetAppState();
          setCookie(Config.cookie.name, uid, { path: '/' });
          queryClient.invalidateQueries();
          const redirectUri = getBaseUrl();
          window.location.href = redirectUri;
        } else {
          const result = await updateClinician({
            input: {
              uid: currentUser.uid,
              metadata: {
                language: payload,
              },
            },
          });
          if (!_.isEmpty(result)) {
            i18n.changeLanguage(payload);
          }
        }
      } catch {
        /* noop */
      } finally {
        refetchUser();
        closeModal();
      }
    },
    [
      closeModal,
      cookies,
      currentUser?.uid,
      domainConfigs?.hippocrates?.region,
      heimdallAuthorizationFlowSessionExchange,
      i18n,
      queryClient,
      resetAppState,
      refetchUser,
      setCookie,
      updateClinician,
    ]
  );

  const actionMenuProps = useMemo(
    () => ({
      openModal,
      closeModal,
      isUpdating,
      isPatientFacing: user?.isPatientFacing ?? false,
      isCaregiverFacing: currentUser?.isCaregiver ?? false,
      onSubmit,
      logout: () => {
        logout();
        return window.location.replace(`${window.location.origin}`);
      },
      selectedlanguage: i18n.language,
      selectedClinic: selectedWorkspace,
      clinics,
    }),
    [
      clinics,
      closeModal,
      currentUser,
      i18n.language,
      isUpdating,
      logout,
      onSubmit,
      openModal,
      selectedWorkspace,
      user,
    ]
  );

  return (
    <>
      <Box id="nav" display={['initial', 'none']}>
        <Flex justifyContent="space-between" alignItems="center" height={64}>
          <ProfileActionMenu
            {...actionMenuProps}
            label={
              <Box
                as="img"
                src={getProfilePic(currentUser)}
                borderRadius="50%"
                width={32}
              />
            }
          />
          <Link to="/dashboard">
            <H4>{clinic && clinic.name}</H4>
          </Link>
          <Box
            cursor="pointer"
            pl={2}
            py={1}
            onClick={() => setDrawerOpen(true)}
          >
            <FAIcon icon={faBars} fontSize="24px" />
          </Box>
        </Flex>
        <Box
          position="relative"
          backgroundColor="#D5D7DE"
          width="calc(100% + 64px)"
          height="1px"
          marginLeft="-24px"
        />
      </Box>
      <Box display={['none', 'initial']}>
        <Flex my={3} justifyContent="space-between" alignItems="center">
          <H4>{clinic && clinic.name}</H4>
          <ProfileActionMenu
            {...actionMenuProps}
            user={currentUser}
            label={<UserInfo user={currentUser} />}
          />
        </Flex>
      </Box>
      <Box display={['initial', 'none']}>
        {authState.authenticated && (
          <Drawer
            width={280}
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
          >
            <Sidebar
              showExpandButton={false}
              defaultExpand
              headerRight={
                <FAIcon
                  icon={faTimes}
                  fontSize="24px"
                  cursor="pointer"
                  pl={2}
                  py={1}
                  onClick={() => setDrawerOpen(false)}
                />
              }
            />
          </Drawer>
        )}
      </Box>
    </>
  );
};

export default Header;
