import React, { useEffect, useState } from 'react';
import {
  Box,
  H2,
  PrimaryButton,
  Flex,
  SecondaryOutlinedButton,
  FAIcon,
  Select,
  Label,
  theme,
} from '@fivehealth/botero';
import { isAndroid } from 'react-device-detect';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    minWidth: '100%',
    fontSize: 14,
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    cursor: 'pointer',
    minHeight: 45,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  clearIndicator: (provided) => ({ ...provided, marginRight: -2 }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer',
    display: 'inline-block',
    paddingLeft: '10px',
    ':before': {
      position: 'absolute',
      left: '5px',
    },
    ':active': {
      color: '#fff',
      backgroundColor: theme.colors.primary,
    },
  }),
  singleValue: (styles) => ({
    ...styles,
  }),
  menuPortal: (base) => ({ ...base, fontSize: 14, zIndex: 9999 }),
};

const ChangeWorkspace = ({
  closeModal,
  onSubmit,
  isMobile,
  selectedClinic,
  clinics,
}) => {
  const { t } = useTranslation();

  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [workspaceOptions, setWorkspaceOptions] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const getClinic = (uid) => clinics.find((o) => o.uid === uid);

  useEffect(() => {
    if (clinics) {
      setWorkspaceOptions(
        clinics.map((o) => ({ label: o.name, value: o.uid }))
      );
    }
    if (selectedClinic) {
      const { name, uid } = selectedClinic;
      setSelectedWorkspace({
        label: name,
        value: uid,
      });
    }
  }, [clinics, selectedClinic]);

  return (
    <Box p={1}>
      <Flex justifyContent="space-between" alignItems="center">
        <H2>{t('Switch workspace')}</H2>
        <Box cursor="pointer" onClick={closeModal}>
          <FAIcon icon={faTimes} hover={{ opacity: 0.6 }} />
        </Box>
      </Flex>

      <Flex mt={3} flexWrap="wrap">
        <Label fontSize={12} color="darkestShade" fontWeight={600} mb={1}>
          {t('Change workspace')}
        </Label>
        <Select
          classNamePrefix="workspace"
          options={workspaceOptions || []}
          styles={customStyles}
          defaultValue={{
            label: selectedClinic?.name,
            value: selectedClinic?.uid,
          }}
          onChange={(e) => {
            if (e.value) {
              setSelectedWorkspace(
                workspaceOptions.find((o) => o.value === e.value)
              );
            }
          }}
          menuPlacement="auto"
          maxMenuHeight={isAndroid ? 150 : 180}
          menuShouldBlockScroll={!isAndroid}
          menuPortalTarget={
            isAndroid ? null : document.getElementById('modalContainer')
          }
        />
      </Flex>
      <Flex
        mt={4}
        pt={isAndroid ? 4 : 0}
        display={isMobile ? 'block !important' : 'flex'}
        justifyContent={isMobile ? 'unset' : 'flex-end'}
        alignItems="center"
      >
        <SecondaryOutlinedButton
          width={isMobile ? '100%' : 'unset'}
          mr={3}
          mb={[1, 0, 0]}
          onClick={closeModal}
          disabled={isSaving}
        >
          {t('Cancel')}
        </SecondaryOutlinedButton>

        <PrimaryButton
          mb={['10px', 0]}
          width={isMobile ? '100%' : 'unset'}
          disabled={isSaving}
          onClick={() => {
            const domain = getClinic(selectedWorkspace.value)?.domain;
            sessionStorage.setItem('clinicDomain', domain);
            setIsSaving(true);
            onSubmit('Workspace', getClinic(selectedWorkspace.value));
          }}
        >
          {isSaving ? t('Saving...') : t('Save')}
        </PrimaryButton>
      </Flex>
    </Box>
  );
};

export default ChangeWorkspace;
