/* eslint-disable no-case-declarations */
import { gql } from 'graphql-request';
import { get, isUndefined, flatMap, chain } from 'lodash';
import {
  sub,
  startOfDay,
  endOfDay,
  formatISO,
  compareDesc,
  parseISO,
} from 'date-fns';
import { createQuery } from '@fivehealth/botero';
import { buildPaginatedResponse } from 'lib/pagination';
import useMonitoringFormsets from './useMonitoringFormsets';

const GRAPHQL_DOCUMENT = gql`
  query cleoSubmissions(
    $orderField: SubmissionOrderBy
    $orderDesc: Boolean
    $first: Int
    $offset: Int
    $afterDateTime: DateTime
    $beforeDateTime: DateTime
    $patientIds: [String]
    $clinicianIcUid: [String!]
    $isAlertsResolved: Boolean
    $patientFormUids: [String]
    $monitoringFormUidIn: [String]
    $patientName: String
  ) {
    cleoSubmissions(
      sortBy: $orderField
      sortDesc: $orderDesc
      first: $first
      offset: $offset
      beforeDateTime: $beforeDateTime
      afterDateTime: $afterDateTime
      patientForm_Uid_In: $patientFormUids
      patientForm_Patient_Uid_In: $patientIds
      isAlertsResolved: $isAlertsResolved
      patientForm_MonitoringForm_Uid_In: $monitoringFormUidIn
      patientSearch: $patientName
      icUidIn: $clinicianIcUid
      alerteeUidIn: $clinicianIcUid
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          uid
          submittedOn
          patient {
            uid
            name
            phone
            communicationMethod
            deactivatedOn
          }
          patientForm {
            uid
            ics {
              uid
              name
            }
            alertees {
              uid
              name
            }
            monitoringForm {
              effectiveName
              url
              monitoringFormset {
                uid
              }
            }
            isDischarged
          }

          isAlertsTriggered
          isAlertsResolved

          triggeredAlerts {
            uid
            rule {
              uid
              name
            }
            alertReasons {
              reason
              submissionValue {
                uid
                value
              }
            }
            createdOn
            resolvingComment {
              uid
              clinician {
                uid
                name
              }
              content
              createdOn
            }
          }

          lastSeenByPatientOn
          lastSeenOn
          lastSeens {
            uid
            clinician {
              uid
              name
            }
            seenOn
            id
          }
          comments {
            uid
            clinician {
              name
              designation
            }
            content
            createdOn
            isSeen
            seenByClinicians {
              uid
            }
            taggedClinicians {
              uid
              name
              isCaregiver
            }
            visibleTo
          }
          values {
            uid
            parameter {
              uid
              groupName
              unit
              name
              valueType
              sortOrder
              unit
              numberPrecision
            }
            value
            valueType
            extracted
            extractedForDisplay
          }
        }
      }
    }
  }
`;

export const lastReports = [
  ...[24, 48, 72].map((num) => ({
    id: `last_${num}_hours`,
    label: `Last ${num} hours`,
    toParams: {
      noReportHours: num,
    },
  })),
  {
    id: 'last_7_days',
    label: 'Last 7 days',
    toParams: {
      noReportHours: 24 * 7,
    },
  },
];

export const dateRange = [
  {
    id: 'today',
    label: 'Today',
    toParams: {
      afterDateTime: formatISO(endOfDay(sub(new Date(), { days: 1 }))),
    },
  },
  {
    id: 'last_7_days',
    label: 'Last 7 Days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(new Date(), { days: 6 }))),
    },
  },
  {
    id: 'last_14_days',
    label: 'Last 14 days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(new Date(), { days: 13 }))),
    },
  },
  {
    id: 'last_30_days',
    label: 'Last 30 days',
    toParams: {
      afterDateTime: formatISO(startOfDay(sub(new Date(), { days: 29 }))),
    },
  },
];
// NOTE: Keep this for now
// export const commentTypes = [
//   {
//     id: 'ICS_AND_ALERTEES',
//     label: 'Private',
//     key: 'icsAndAlertees',
//     toParams: { visibileTo: 'ICS_AND_ALERTEES' },
//   },
//   {
//     id: 'EVERYBODY',
//     label: 'Public',
//     key: 'everybody',
//     toParams: { visibileTo: 'EVERYBODY' },
//   },
// ];

export const mapSubmission = (submissionData) => {
  let status = 'stable';
  const alert = get(submissionData, 'triggeredAlerts');
  if (alert.length > 0) {
    status = flatMap(alert.map((a) => a.resolvingComment)).find((c) => c)
      ? 'danger'
      : 'warning';
  }

  let statusColor = status;
  if (status === 'stable') {
    statusColor = 'success';
  } else if (status === 'warning') {
    statusColor = 'danger';
  }

  const clinicalUsers = submissionData?.patientForm?.ics
    ?.map(({ name }) => name || '')
    ?.join(', ');

  const values = chain(submissionData)
    .get('values', [])
    .sortBy(({ parameter: { sortOrder } }) => sortOrder)
    .value();

  const groupedParameters = values.reduce((acc, param) => {
    const { groupName } = param.parameter || {};
    if (groupName && groupName.length > 0) {
      acc[groupName] = [...(acc[groupName] || []), param];
    }
    return acc;
  }, {});

  const submissionParameters = values
    .map((value) => {
      const {
        extractedForDisplay: parameterValue,
        parameter: { groupName, name } = {},
        valueType,
      } = value || {};

      if (groupedParameters[groupName]) {
        const groupValues = groupedParameters[groupName]
          .map(({ extractedForDisplay }) => extractedForDisplay)
          .join('/');
        delete groupedParameters[groupName];
        if (!groupName) {
          return '';
        }
        return `${groupName}: ${groupValues || 'N/A'}`;
      }
      if (groupName) {
        return null;
      }
      if (name && valueType === 'FILE' && parameterValue) {
        return `[${name}](${parameterValue})`;
      }
      if (name) {
        return `${name}: ${parameterValue || 'N/A'}`;
      }
      return '';
    })
    .filter(Boolean)
    .join(', ');

  const data = {
    ...submissionData,
    patientUid: submissionData.patient?.uid,
    name: submissionData.patient?.name,
    phone: submissionData.patient?.phone,
    identifier: submissionData.patient?.identifier,
    monitoringFormUrl: submissionData.patientForm?.monitoringForm?.url,
    monitoringFormName:
      submissionData.patientForm?.monitoringForm?.effectiveName,
    clinicalUsers,
    status,
    statusColor,
    submissionParameters,
    parameters: [],
    submissionGroupedParams: [],
  };

  return data;
};

const useSubmissions = () => (args) => {
  // TODO: React Hook "useMonitoringFormsets" cannot be called inside a callback.
  /* eslint-disable react-hooks/rules-of-hooks */
  const { data: monitoringFormsets } = useMonitoringFormsets({
    variables: {
      isEnrollmentForm: false,
    },
  });
  const { paginate } = args;
  const submissions = createQuery({
    gqlDocument: GRAPHQL_DOCUMENT,
    queryType: 'query',
    baseQueryKey: 'submissionsUnresolved',
    paginate: isUndefined(paginate) ? 'beforeDateTime' : paginate,
    filters: {
      lastReport: {
        id: 'lastReport',
        title: 'No reports',
        data: lastReports,
      },
      dateRange: { id: 'dateRange', title: 'Date range', data: dateRange },
      monitoringFormsets: {
        id: 'monitoringFormsets',
        title: 'Programs',
        data: monitoringFormsets,
        multiSelect: true,
      },
    },
    getNextPageParam: ({ cleoSubmissions: data } = {}, allPages) => {
      if (paginate === 'offset') {
        const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
        if (!hasNextPage) {
          return undefined;
        }

        const currentOffset = allPages.flatMap(({ cleoSubmissions }) =>
          get(cleoSubmissions, 'edges', [])
        ).length;
        return currentOffset;
      }

      const hasNextPage = get(data, 'pageInfo.hasNextPage', false);
      if (!hasNextPage) {
        return undefined;
      }
      const edges = get(data, 'edges', [])
        .map(({ node }) => node)
        .filter(Boolean);

      const sortedData = edges.sort((a, b) =>
        compareDesc(parseISO(a.submittedOn), parseISO(b.submittedOn))
      );
      return get(sortedData[sortedData.length - 1], 'submittedOn');
    },
    select: (data) =>
      buildPaginatedResponse(data, 'cleoSubmissions', mapSubmission),
    keepPreviousData: true,
  });
  return submissions(args);
};

// TODO: React Hook "useSubmissions" cannot be called at the top level.
/* eslint-disable react-hooks/rules-of-hooks */
export default useSubmissions();
